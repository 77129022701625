import React, { useState } from "react";
import {
    withGoogleMap,
    GoogleMap,
    Polygon,
    withScriptjs,
} from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const refs = {
    map: undefined,
}


const MapWithAMarker = withScriptjs(
    withGoogleMap(({ polygonCoords, setPolygonCoords, geojson, center, selectedArea, editCoords }) => {
        let path;
        const [polygonPath, setPolygonPath] = useState(polygonCoords);
        const [drawingMode, setDrawingMode] = useState(window.google.maps.drawing.OverlayType.POLYGON);

        const handlePolygonCompleted = (polygon) => {
            const updatedPath = polygon
                .getPath()
                .getArray()
                .map((latLng) => ([latLng.lng(), latLng.lat()]));
            setPolygonCoords(updatedPath);
            setPolygonPath(polygon.getPath());
            setDrawingMode(null);

        };

        const handlePolygonChanged = () => {
            const updatedPath = polygonPath
                .getArray()
                .map((latLng) => ([latLng.lng(), latLng.lat()]));
            setPolygonCoords(updatedPath);
        };

        if (geojson?.type === "MultiPolygon") {
            path = {}
            geojson && geojson.coordinates && Object.keys(geojson.coordinates).map(recc => {
                let polys = geojson.coordinates?.[recc]?.reduce((val, currentVal) => {
                    val.push({ lat: currentVal.latitude, lng: currentVal.longitude })
                    return val
                }, [])
                return path[recc] = polys
            })
        } else {
            path = geojson?.coordinates?.reduce((val, currentVal) => {
                val.push({ lat: currentVal.latitude, lng: currentVal.longitude })
                return val
            }, [])
        }
        return (

            <GoogleMap
                ref={(ref) => refs.map = ref}
                defaultZoom={12}
                options={{ disableDefaultUI: true }}
                center={center?.lat && center?.lng ? center : {
                    lat: 25.1903,
                    lng: 55.2747,
                }}
                defaultCenter={center?.lat && center?.lng ? center : {
                    lat: 25.1903,
                    lng: 55.2747,
                }}
                onClick={(event) => {
                    if (!["MultiPolygon", "Polygon"].includes(geojson?.type) || editCoords) {
                        setPolygonCoords([...polygonCoords, [event.latLng.lng(), event.latLng.lat()]])
                    }
                }}
            >
                {geojson?.type === "MultiPolygon" && !editCoords ?
                    Object.values(path).map(p => <Polygon path={p}
                        options={{
                            fillColor: "red",
                            fillOpacity: 0.4,
                            strokeColor: "red",
                            strokeOpacity: 1,
                            strokeWeight: 1
                        }}
                    />)
                    : geojson?.type === "Polygon" && !editCoords ? <Polygon path={path}
                        options={{
                            fillColor: "red",
                            fillOpacity: 0.4,
                            strokeColor: "red",
                            strokeOpacity: 1,
                            strokeWeight: 1
                        }}
                    /> :
                        <>
                            <DrawingManager
                                defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
                                drawingMode={drawingMode} // Set the drawing mode to a state variable
                                defaultOptions={{
                                    drawingControl: false,
                                    polygonOptions: {
                                        fillColor: "red",
                                        fillOpacity: 0.4,
                                        strokeColor: "red",
                                        strokeOpacity: 1,
                                        strokeWeight: 2,
                                    }
                                }}
                                onPolygonComplete={handlePolygonCompleted}
                            />
                            {polygonCoords.length > 0 && (
                                <Polygon
                                    path={polygonPath}
                                    editable
                                    onMouseUp={handlePolygonChanged}
                                    options={{
                                        fillColor: "red",
                                        fillOpacity: 0.4,
                                        strokeColor: "red",
                                        strokeOpacity: 1,
                                        strokeWeight: 1
                                    }}
                                />
                            )}
                        </>

                }
            </GoogleMap>
        )
    }
    )
);


const GMap = ({ selectedArea, dismiss, addLocation }) => {
    const [editCoords, setEditCoords] = useState(false);

    const coordinate = (cord) => {
        let x = (cord?.type === 'MultiPolygon' ? Object.values(cord?.coordinates)?.[0] : cord?.coordinates)?.map(c => c.latitude)
        let y = (cord?.type === 'MultiPolygon' ? Object.values(cord?.coordinates)?.[0] : cord?.coordinates)?.map(c => c.longitude)

        let minX = Math.min.apply(null, x)
        let maxX = Math.max.apply(null, x)

        let minY = Math.min.apply(null, y)
        let maxY = Math.max.apply(null, y)

        return {
            lat: (minX + maxX) / 2,
            lng: (minY + maxY) / 2
        }
    }


    const [polygonCoords, setPolygonCoords] = useState([]);
    return (
        <Modal
            backdrop="static" isOpen={selectedArea}
            size="lg">
            <ModalHeader>
                <h3>{selectedArea?.name}</h3>
                {selectedArea?.newLocation && <Button color='primary' onClick={() => setEditCoords(!editCoords)}>Edit Coordinates</Button>}
            </ModalHeader>
            <ModalBody>
                <MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc&libraries=geometry,drawing,places"
                    loadingElement={<div className="map-item" />}
                    containerElement={<div className="map-item" />}
                    mapElement={<div className="map-item" />}
                    geojson={selectedArea?.pathCoordinates}
                    center={coordinate(selectedArea?.pathCoordinates)}
                    polygonCoords={polygonCoords}
                    editCoords={editCoords}
                    setPolygonCoords={setPolygonCoords}

                    selectedArea={selectedArea}
                />
            </ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={() => {
                    dismiss()
                }}>Dismiss</Button>
                {selectedArea?.newLocation && <Button color='primary' onClick={() => {
                    if (polygonCoords?.length) {
                        addLocation(polygonCoords)
                    } else {
                        addLocation()
                    }
                }}>Add Location</Button>}
            </ModalFooter>
        </Modal>
    )
};

export default GMap;