import React, { useEffect, useState } from 'react';
import { Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, Label, Input, Table } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import Select, { components,  } from 'react-select';
import { firestore } from '../../../../helpers/Firebase';

const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;

};

const Variation = ({ selectedWasher, selectedVariation, service, addVariation, editVariation, dismiss, carKey, carType, availableVariation, addAvailableVariation }) => {
    const [variation, setVariation] = useState({})
    const [data, setData] = useState([{ heatRejection: '', darknessLevels: [], allSidePrice: {}, frontSidePrice: {}, sidesBackPrice: {}, roofTintingPrice: {} }])
    useEffect(() => {
        if (editVariation) {
            setData(selectedVariation?.data?.sort((a, b) => Number(a.heatRejection.replace("%", '')) > Number(b.heatRejection.replace("%", '')) ? 1 : -1))
        }
        setVariation(selectedVariation)
    }, [selectedVariation, editVariation])

   

    const handleDateChange = (e, itemIndex, type) => {
        let newVariation = JSON.parse(JSON.stringify(variation))
        let newData = JSON.parse(JSON.stringify(data))

        // Check if 'type' exists in newData[itemIndex], if not, initialize it
        if (!newData[itemIndex][type]) {
            newData[itemIndex][type] = {};
        }

        newData[itemIndex][type][carKey] = e.target.value;

        setData(newData);
        newVariation.data = newData;
        setVariation(newVariation);
    };

    let UpdateVariation = () => {
        if (addVariation) {
            let key = firestore().collection('tintingVariations').doc().id
            delete variation.label
            delete variation.value

            variation.key = key
            variation.sp_id = selectedWasher.sp_id
            variation.worker_id = selectedWasher.key
            firestore().collection('tintingVariations').doc(key).set(variation).then(() => {
                dismiss(key)
            })
        } else {
            firestore().collection('tintingVariations').doc(variation?.key).update(variation).then(() => {
                if (availableVariation) {
                    addAvailableVariation()
                } else {
                    dismiss()
                }

            })
        }

    }
    let active = data?.reduce((value, currentValue) => {
        // eslint-disable-next-line 
        Object.keys(currentValue).map(rec => {
            switch (rec) {
                case 'heatRejection': case 'darknessLevels':
                    if (!currentValue[rec].length) {
                        value = false
                        return value
                    }
                    break;


                default:
                    if (!currentValue[rec][carKey]) {
                        value = false
                        return value
                    }
                    break;
            }


        })

        return value

    }, true)

    return (
        <Modal
            size='lg'
            isOpen={addVariation || editVariation}
            style={{ maxWidth: '1200px' }}
            backdrop="static">
            <ModalHeader >Edit vehicle type price, duration and variations</ModalHeader>
            <ModalBody>
                <Row>
                    <Colxx xxs="6" className="mt-2" >
                        <Label >* Name</Label>
                        <Input className="customInput"
                            value={variation?.name}
                            placeholder="e.g. China" type="text" onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                newVariation.name = e.target.value
                                setVariation(newVariation)
                            }} />
                    </Colxx>
                    <Colxx xxs="6" className="mt-2" >
                        <Label >* Arabic Name</Label>
                        <Input className="customInput"
                            value={variation?.name_ar}
                            placeholder="e.g. China" type="text" onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                newVariation.name_ar = e.target.value
                                setVariation(newVariation)
                            }} />
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs="6" className="mt-2" >
                        <Label >Made in</Label>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            value={variation?.made_in ? { value: variation?.made_in, label: variation?.made_in, key: variation?.made_in } : ''}
                            placeholder="e.g. China"
                            onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                newVariation.made_in = e.value
                                setVariation(newVariation)

                            }}
                            options={service.madeIn.map(option => { return option !== variation?.made_in && { value: option, label: option, key: option } }).filter(rec => rec)}
                        />

                    </Colxx>
                    <Colxx xxs="6" className="mt-2">
                        <Label >Brand</Label>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            value={variation?.brand ? { value: variation?.brand, label: variation?.brand, key: variation?.brand } : ''}
                            placeholder="e.g. V-Kool"
                            onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                newVariation.brand = e.value
                                setVariation(newVariation)

                            }}
                            options={service.brands.map(option => { return option !== variation?.brand && { value: option, label: option, key: option } }).filter(rec => rec)}
                        />
                    </Colxx>
                </Row>
                <Row>
                    <Colxx xxs="6" className="mt-4">
                        <Label >Description</Label>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            value={''}
                            options={(service?.description || [])?.map(item => ({ label: item, value: item, key: item }))?.filter(item => {
                                const isOptionUnavailable = variation?.description?.includes(item.key);
                                return !isOptionUnavailable;
                            })}
                            onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                let newDescriptions = newVariation?.description || []
                                newDescriptions.push(e.value)
                                newVariation.description = newDescriptions
                                setVariation(newVariation)
                            }}
                            placeholder="Add Description"
                        />
                        {(variation?.description)?.length ? <div className="mt-2"
                            style={{
                                minHeight: 'auto',
                                borderRadius: '3px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                            }}>
                            {(variation?.description)?.map((item, index) => {
                                return (
                                    <div key={index} style={{
                                        margin: '5px',
                                        padding: ' 5px 10px',
                                        border: '1px solid #d7d7d7',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{ marginRight: '8px' }}>{item}</div>
                                        <i style={{ cursor: 'pointer' }} className="simple-icon-close" onClick={() => {
                                            let newVariation = JSON.parse(JSON.stringify(variation))

                                            let newDescriptions = newVariation?.description.filter((deletedItem) => {
                                                return (deletedItem !== item)
                                            })
                                            newVariation.description = newDescriptions
                                            setVariation(newVariation)
                                        }} />
                                    </div>
                                )
                            })}
                        </div> : ""
                        }
                    </Colxx>
                    <Colxx xxs="6" className="mt-4" >
                        <Label >Arabic Description</Label>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Add Arabic Description"
                            value={''}
                            options={(service?.description_ar || [])?.map(item => ({ label: item, value: item, key: item }))?.filter(item => {
                                const isOptionUnavailable = variation?.description_ar?.includes(item.key);
                                return !isOptionUnavailable;
                            })}
                            name="form-field-name"
                            onChange={(e) => {
                                let newVariation = JSON.parse(JSON.stringify(variation))
                                let newDescriptions = newVariation?.description_ar || []
                                newDescriptions.push(e.value)
                                newVariation.description_ar = newDescriptions
                                setVariation(newVariation)
                            }}
                        />
                        {(variation?.description_ar)?.length ? <div className="mt-2"
                            style={{
                                minHeight: 'auto',
                                borderRadius: '3px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                            }}>
                            {variation?.description_ar?.map((item, index) => {
                                return (
                                    <div key={index} style={{
                                        margin: '5px',
                                        padding: ' 5px 10px',
                                        border: '1px solid #d7d7d7',
                                        borderRadius: '8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <div style={{ marginRight: '8px' }}>{item}</div>
                                        <i style={{ cursor: 'pointer' }} className="simple-icon-close" onClick={() => {
                                            let newVariation = JSON.parse(JSON.stringify(variation))

                                            let newDescriptions = newVariation?.description_ar.filter((deletedItem) => {
                                                return (deletedItem !== item)
                                            })
                                            newVariation.description_ar = newDescriptions
                                            setVariation(newVariation)

                                        }} />
                                    </div>
                                )
                            })}
                        </div> : ""
                        }
                    </Colxx>
                </Row>

                <Row>
                    <Colxx xxs="12" className="mt-4" >
                        <Label >Options</Label>
                        {

                            data && data?.length ?

                                <Table borderless>
                                    <thead>
                                        <tr>
                                            <th className="text-semi-muted mb-2" style={{ width: '130px' }}>
                                                * Heat Rejection
                                            </th>
                                            <th className="text-semi-muted mb-2">
                                                * Tint Levels
                                            </th>
                                            <th className="text-semi-muted mb-2">
                                                * Front Side Price ({<spna style={{ color: "#000" }}>{carType}</spna>})
                                            </th>

                                            <th className="text-semi-muted mb-2">
                                                * Sides & Back Price ({<spna style={{ color: "#000" }}>{carType}</spna>})
                                            </th>

                                            <th className="text-semi-muted mb-2">
                                                * Roof Tinting Price ({<spna style={{ color: "#000" }}>{carType}</spna>})
                                            </th>

                                            <th className="text-semi-muted mb-2">
                                                * All Sides Price ({<spna style={{ color: "#000" }}>{carType}</spna>})
                                            </th>

                                            <th className="text-semi-muted mb-2">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data?.map((item, itemIndex) => {
                                                return (
                                                    <tr key={itemIndex}>
                                                        <td>
                                                            <Select
                                                                components={{ Input: CustomSelectInput }}
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                name="form-field-name"
                                                                value={item.heatRejection ? { value: item.heatRejection, label: item.heatRejection, key: item.heatRejection } : ''}
                                                                placeholder="e.g. 10%"
                                                                onChange={(e) => {
                                                                    let newData = JSON.parse(JSON.stringify(data))
                                                                    newData[itemIndex].heatRejection = e.key
                                                                    setData(newData)

                                                                    let newVariation = JSON.parse(JSON.stringify(variation))
                                                                    newVariation.data = newData
                                                                    setVariation(newVariation)

                                                                }}
                                                                options={service?.heatLevels?.filter(rec => !variation?.data?.map(heat => heat?.heatRejection)?.includes(rec))?.sort((a, b) => Number(a.replace("%", '')) > Number(b.replace("%", '')) ? 1 : -1)?.map(option => { return data.heatRejection !== option && { value: option, label: option, key: option } }).filter(rec => rec)}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Select
                                                                placeholder="Select Tint Levels"
                                                                isMulti
                                                                name="levels"
                                                                styles={{ minHeight: '43px', minWidth: '150px', maxWidth: '200px' }}
                                                                options={service?.tintLevels?.sort((a, b) => Number(a.replace("%", '')) > Number(b.replace("%", '')) ? 1 : -1)?.map(option => { return !data?.darknessLevels?.includes(option) && { value: option, label: option, key: option } }).filter(rec => rec)}
                                                                value={item?.darknessLevels?.sort((a, b) => Number(a.replace("%", '')) > Number(b.replace("%", '')) ? 1 : -1)?.map(rec => { return { value: rec, key: rec, label: rec } })}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={(val) => {
                                                                    let levels = val.map(rec => rec.value)
                                                                    let newVariation = JSON.parse(JSON.stringify(variation))
                                                                    let newData = JSON.parse(JSON.stringify(data))
                                                                    newData[itemIndex].darknessLevels = levels
                                                                    setData(newData)
                                                                    newVariation.data = newData
                                                                    setVariation(newVariation)
                                                                }}
                                                            />
                                                        </td>

                                                        <td>
                                                            <Input className="customInput"
                                                                value={item?.frontSidePrice?.[carKey]}
                                                                placeholder="e.g. 10 AED"
                                                                type="number"
                                                                onChange={(e) => handleDateChange(e, itemIndex, 'frontSidePrice')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input className="customInput"
                                                                value={item?.sidesBackPrice?.[carKey]}
                                                                placeholder="e.g. 10 AED" type="number"
                                                                onChange={(e) => handleDateChange(e, itemIndex, 'sidesBackPrice')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input className="customInput"
                                                                value={item?.roofTintingPrice?.[carKey]}
                                                                placeholder="e.g. 10 AED" type="number"
                                                                onChange={(e) => handleDateChange(e, itemIndex, 'roofTintingPrice')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input className="customInput"
                                                                value={item?.allSidePrice?.[carKey]}
                                                                placeholder="e.g. 10 AED" type="number"
                                                                onChange={(e) => handleDateChange(e, itemIndex, 'allSidePrice')}
                                                            />
                                                        </td>


                                                        {itemIndex === 0 ?
                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                <div style={{
                                                                    fontSize: '12px',
                                                                    fontWeight: '600',
                                                                    color: '#3498db',
                                                                    cursor: 'pointer',
                                                                    width: '100px'
                                                                }} onClick={() => {
                                                                    let newData = JSON.parse(JSON.stringify(data))
                                                                    newData.push({ heatRejection: '', darknessLevels: [], allSidePrice: {}, frontSidePrice: {}, sidesBackPrice: {}, roofTintingPrice: {} })
                                                                    setData(newData)

                                                                    let newVariation = JSON.parse(JSON.stringify(variation))
                                                                    newVariation.data = newData
                                                                    setVariation(newVariation)

                                                                }}><i className="simple-icon-plus" /> Add variation</div>
                                                            </td>
                                                            :
                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                <div style={{
                                                                    fontSize: '14px',
                                                                    fontWeight: '600',
                                                                    color: '#d7d7d7',
                                                                    cursor: 'pointer',
                                                                    width: '100px'
                                                                }} onClick={() => {
                                                                    let newData = JSON.parse(JSON.stringify(data)).filter(rec => rec.heatRejection !== item.heatRejection)
                                                                    newData = newData?.length ? newData : [{ heatRejection: '', darknessLevels: [], allSidePrice: {}, frontSidePrice: {}, sidesBackPrice: {}, roofTintingPrice: {} }]

                                                                    setData(newData)
                                                                    let newVariation = JSON.parse(JSON.stringify(variation))
                                                                    newVariation.data = newData
                                                                    setVariation(newVariation)
                                                                }}><i className="simple-icon-close" /></div>
                                                            </td>
                                                        }

                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </Table>
                                :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No Options</div>


                        }

                    </Colxx>
                </Row>
            </ModalBody>
            <ModalFooter >
                <Button color="danger" outline onClick={() => dismiss()}>Cancel</Button>
                <Button disabled={!active} color="primary" className="top-right-button" onClick={() => {
                    UpdateVariation()
                }}>{editVariation ? 'Save Variation' : 'Add Variation'}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default Variation;