import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";


import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import classnames from "classnames";
import vatDisclaimer from '../../../assets/css/img/vatDisclaimer.pdf';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Resizer from 'react-image-file-resizer';
import ImageUploader from 'react-images-upload';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { firestore, functions, storage } from '../../../helpers/Firebase';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import Geocode from "react-geocode";
import { useSelector } from "react-redux";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { NotificationManager } from "../../../components/common/react-notifications";
import { accessTypes, accessTypesObject, uploadFile } from "../../../helpers/Utils";
import MapWithMarker from "./components/MapWithMarker";
import Subscriptions from "./components/subscriptions";
Geocode.setApiKey("AIzaSyCExglJjHXgXzSGHJB1NmLll7NgjOG8_bc");
Geocode.setLanguage("en")

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);
const Outlets = ({
  selectedOutlet,
  dismiss,
  addNewBranch,
  services,
  admins
}) => {
  const { user } = useSelector(state => state.authUser);

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [providerPictures, setProviderPictures] = useState([])
  const [updatedProvider, UpdatedProvider] = useState(selectedOutlet || {})
  console.log('updatedProvider', updatedProvider)

  const [changeEmail, UpdateEmail] = useState(false)
  const [changeNumber, UpdateNumber] = useState(false)
  const [resetPassword, UpdatePassword] = useState(false)

  const [tabs] = useState([
    { name: 'Provider Information', key: 1 },
    { name: 'Account Information', key: 2, hide: !accessTypes.includes(user?.accessType) || user?.accessType === 'marketing' },
    { name: 'Subscriptions', key: 3 },
    { name: 'Sponsorship', key: 4, hide: !accessTypes.includes(user?.accessType) || user?.accessType === 'marketing' },
    { name: 'VAT', key: 5, },
    { name: 'Settings', key: 6, hide: !accessTypes.includes(user?.accessType) }

  ])

  const onDrop = (picture, type) => {
    let pictures = []
    if (picture.length) {
      let lastPic = picture[picture.length - 1]
      Resizer.imageFileResizer(
        lastPic,
        720,
        1080,
        'jpeg',
        60,
        0,
        uri600 => {
          pictures.push({
            name: '600',
            data: uri600
          });
          switch (type) {
            case 'pictures':
              setProviderPictures(pictures)
              break;
            default:
              break;
          }
        },
        'blob'
      );

    } else {
      switch (type) {
        case 'pictures':
          setProviderPictures(pictures)
          break;

        default:
          break;
      }

      UpdatedProvider({ ...updatedProvider, [type]: [] })
    }
  }
  const createNotification = (className, error) => {
    let cName = className || "";
    return (
      NotificationManager.error(
        error,
        "Error",
        2000,
        null,
        cName
      )
    )
  };
  const submit = async () => {
    let general = updatedProvider;
    let subscriptions = general?.subscriptions || {}
    delete general?.subscriptions
    let id = general.key || firestore().collection('service_provider').doc().id;

    general.updated = Date.now()
    general.key = id

    if (addNewBranch) {
      general.active = true
      general.created = Date.now()
      general.uniqueID = (Math.floor(Math.random(new Date().getTime()) * 90000000) + 10000000).toString()

      if (general.password === general.confirmPassword && general.email) {
        const authusers = functions().httpsCallable('setRoles');
        authusers({ email: general.email, pw: general.password, role: 'admin', displayName: general.name }).then(function (result) {
          let { user } = result.data
          if (!result.data.error) {
            let key = user.uid
            delete general.password
            delete general.confirmPassword
            if (general?.vatCertificate?.files) {
              uploadFile(general.vatCertificate?.files, 'service_provider', id, 'vatCertificate')
              delete general.vatCertificate?.files
            }
            firestore().collection('service_provider').doc(key).set(general, { merge: true }).then(() => {
              dismiss()
            })
          } else {
            if (result.data.error.errorInfo.code.includes('invalid-phone-number')) {
              createNotification("error", "Invalid phone number")
              setLoading(false)
            } else if (result.data.error.errorInfo.code.includes('auth/phone-number-already-exists')) {
              createNotification("error", "This number already exists")
              setLoading(false)
            } else if (result.data.error.errorInfo.code.includes('invalid-data')) {
              createNotification("error", "Kindly enter all required data")
              setLoading(false)
            }
          }
        });
      } else {
        if (!general.email) {
          createNotification("error", "Kindly enter all required data")
          setLoading(false)
        }
        // else if (!general.number) {
        //   createNotification("error", "Invalid phone number")
        //   setLoading(false)
        // }
        else if (general.password !== general.confirmPassword) {
          setLoading(false)
          createNotification("error", "password Mismatch")
        }
      }
    } else {
      if (general?.vatCertificate?.files) {
        uploadFile(general.vatCertificate?.files, 'service_provider', id, 'vatCertificate')
        delete general.vatCertificate?.files
      }
      await firestore().collection('service_provider').doc(id).set(general, { merge: true }).then(() => {
        if (Object.values(subscriptions).length) {
          let updatedData = Object.keys(subscriptions)
          updatedData.map(async rec => {
            let Ref = firestore().collection('service_provider').doc(id).collection('subscriptions')
            let documentData = subscriptions[rec]
            if (documentData.deleted) {
              await Ref.doc(rec).delete();

            } else {

              delete documentData.updated
              delete documentData.value
              delete documentData.label
              await Ref.doc(rec).set(documentData);
            }

          })
        }
        dismiss()

      })
      setLoading(false)
    }


    if (providerPictures && providerPictures.length > 0 && providerPictures[0].name) {
      var metadata = {
        cacheControl: 'public,max-age=300',
      };
      providerPictures.forEach(element => {
        var storageRef = storage().ref().child('service_provider').child(id).child("logo").put(element.data, metadata);
        storageRef.on('state_changed', function (snapshot) {
        }, function (error) {
          console.log(error)
          // Handle unsuccessful uploads
        }, function () {
          storageRef.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            firestore().collection('service_provider').doc(id).set({
              pictures: [downloadURL],
              updated: Date.now(),
            }, { merge: true })
          })
        })
      });
    }



  }
  useEffect(() => {

    async function fetchData() {
      if (selectedOutlet?.key) {
        let snap = await firestore().collection('service_provider').doc(selectedOutlet?.key).collection('subscriptions').get()
        if (!snap.empty) {
          let data = snap?.docs?.reduce((val, currentVal) => {
            val[currentVal.id] = { ...currentVal.data() }
            return val
          }, {})
          return UpdatedProvider({ ...updatedProvider, subscriptions: data })

        }

      }

    }
    fetchData();
    // eslint-disable-next-line
  }, [selectedOutlet])
  useEffect(() => {
    if (updatedProvider?.latLng) {
      Geocode.fromLatLng(updatedProvider?.latLng.lat, updatedProvider?.latLng.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let area;
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
              switch (response.results[0].address_components[i].types[j]) {
                case "neighborhood":
                  area = response.results[0].address_components[i].short_name;
                  break;
                case "sublocality":
                  area = response.results[0].address_components[i].short_name;
                  break;
                default:
                  area = 'N/A'
                  break
              }
            }
          }
          UpdatedProvider((u) => ({ ...u, latLng: u?.latLng, area: area, address: address }))

        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [updatedProvider?.latLng])
  return (
    (
      loading ? (
        <div className="loading" />
      ) : (
        <Fragment>
          <Row>
            <Colxx xxs="12">
              <h1>{addNewBranch ? 'New Provider' : updatedProvider?.name}</h1>
              <Nav tabs className="separator-tabs ml-0 mb-5">
                {
                  tabs.map(item => {
                    return !item.hide && (
                      <div
                        style={{ cursor: 'pointer' }}
                        key={item.key}
                        className={classnames({
                          active: activeTab === item.key,
                          "nav-link": true
                        })}
                        onClick={() => {
                          setActiveTab(item.key);
                        }}>
                        {item.name}
                      </div>
                    )
                  })
                }
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <Card className="rounded">
                    <CardBody>
                      {[{
                        key: 'responsibleAgent', title: 'Responsible Agent', hide: !accessTypes.includes(user?.accessType),
                        data: [
                          { key: 'agent', title: 'Agent', width: '10', type: 'select', data: admins },
                        ]
                      },
                      {
                        key: 'generalInfo', title: 'Provider Information',
                        data: [
                          { key: 'pictures', title: 'Provider Picture', width: '10', type: 'picture' },
                          { key: 'name', title: 'Name', width: '5', type: 'text' },
                          { key: 'name_ar', title: 'Arabic Name', width: '5', type: 'text' },
                          { key: 'description', title: 'Details', width: '10', type: 'textarea' },
                          { key: 'description_ar', title: 'Arabic Details', width: '10', type: 'textarea' },
                        ]
                      },
                      {
                        key: 'accountInfo', title: 'Account Information',
                        data: [
                          { key: 'email', title: 'Email', width: '10', type: 'email' },
                          { key: 'password', title: 'Password', width: '10', type: 'password', hide: !addNewBranch },
                          { key: 'confirmPassword', title: 'Confirm Password', width: '10', type: 'password', hide: !addNewBranch },
                          { key: 'number', title: 'Number', width: '10', type: 'phoneNumber' },
                          { key: 'callCenter', title: 'Call Center', width: '10', type: 'phoneNumber' },
                        ]
                      },
                      {
                        key: 'addressInfo', title: 'Address Information',
                        data: [
                          { key: 'address', title: 'Address', width: '5', type: 'text' },
                          { key: 'address_ar', title: 'Arabic Address', width: '5', type: 'text' },
                          { key: 'area', title: 'Area', width: '5', type: 'text' },
                          { key: 'area_ar', title: 'Arabic Area', width: '5', type: 'text' }
                        ]
                      }].map((rec, recIndex) => {
                        return (
                          <Row key={recIndex} className="w-80">
                            <Colxx xxs="12" className="mb-2">
                              <h4>{rec.title}</h4>
                            </Colxx>
                            {rec.key === 'addressInfo' &&
                              <Colxx xxs="10" className="mb-4">

                                <MapWithMarker
                                  updateLatLng={(latLng) => UpdatedProvider({ ...updatedProvider, latLng: latLng })}
                                  center={updatedProvider?.latLng || { lat: 25.1903, lng: 55.2747 }}
                                />
                              </Colxx>}
                            {rec.data.map((item, index) => {
                              return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                  <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                    <div>{item.title}</div>
                                    {item.key === 'callCenter' && <Switch
                                      className="custom-switch custom-switch-primary"
                                      checked={updatedProvider?.callCenter?.active}
                                      onChange={switchCheckedPrimary => {
                                        UpdatedProvider({ ...updatedProvider, callCenter: { ...updatedProvider?.callCenter, active: switchCheckedPrimary } })
                                      }}
                                    />}
                                  </div>
                                  {item.type === 'picture' ?
                                    <ImageUploader
                                      className={'w-100'}
                                      withIcon={true}
                                      name={item.key}
                                      label={'Max file size: 15mb, accepted: jpg|gif|png'}
                                      buttonText='Choose images'
                                      onChange={(picture) => onDrop(picture, item.key)}
                                      imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                      maxFileSize={15728640}
                                      withPreview={true}
                                      singleImage={true}
                                      defaultImages={updatedProvider && updatedProvider[item.key]}
                                    /> : item.type === 'phoneNumber' ?
                                      <PhoneInput
                                        regions={['middle-east', 'north-africa']}
                                        inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                        className="customInput"
                                        country={'ae'}
                                        // disabled={!addNewBranch && item.key !== 'callCenter'}
                                        placeholder="e.g. +971xxxxxx"
                                        value={item.key === 'callCenter' ? updatedProvider?.[item.key]?.number : updatedProvider?.[item.key]}
                                        onChange={(value, data, event, formattedValue) => {
                                          if (item.key === 'callCenter') {
                                            UpdatedProvider({ ...updatedProvider, callCenter: { ...updatedProvider?.callCenter, number: formattedValue } })

                                          } else {
                                            UpdatedProvider({ ...updatedProvider, [item.key]: formattedValue })

                                          }
                                        }}
                                      /> : item.type === 'select' ?
                                        <Select

                                          components={{ Input: CustomSelectInput }}
                                          className="basic-select"
                                          placeholder={item.title}
                                          value={item?.data?.map(rec => {
                                            return (updatedProvider?.[item.key]?.includes(rec.id)) ?
                                              ({ ...rec, value: rec?.name?.en || rec.name, label: rec?.name?.en || rec.name, key: rec.id }) : ''
                                          })
                                          }
                                          options={item?.data?.map(rec => {
                                            return ({ ...rec, value: rec.name, label: rec.name, key: rec.id || rec.key })
                                          })}
                                          getOptionLabel={option => {
                                            return (
                                              <div>
                                                {option.name}
                                                <Badge color={'primary'} className={'ml-2'} pill>
                                                  {accessTypesObject?.[option.role]?.name}
                                                </Badge>
                                              </div>
                                            )
                                          }}
                                          getOptionValue={(option) => { return option.key || option.id }}
                                          disabled={!accessTypes.includes(user?.accessType)}
                                          onChange={val => {
                                            UpdatedProvider({ ...updatedProvider, [item.key]: val?.key })
                                          }}
                                        />
                                        : item.type === 'dropzone' ?
                                          <InputGroup>
                                            <CustomInput
                                              type="file"
                                              id="exampleCustomFileBrowser4"
                                              name="customFile"
                                              label={updatedProvider?.[item.key]?.name}
                                              onChange={async ({ target: { files } }) => {
                                                const [{ name }] = files;
                                                UpdatedProvider({ ...updatedProvider, [item.key]: { name: name, files: files } })
                                              }}
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button outline color="danger" onClick={() => {
                                                UpdatedProvider({ ...updatedProvider, [item.key]: {} })


                                              }}>
                                                <i className="glyph-icon simple-icon-trash" />
                                              </Button>
                                            </InputGroupAddon>
                                            {updatedProvider?.[item.key]?.license ? <InputGroupAddon addonType="append">
                                              <Button outline color="primary" onClick={() => {
                                                window.open(updatedProvider?.[item.key]?.license, '_blank')
                                              }}>
                                                <i className="glyph-icon simple-icon-eye" />
                                              </Button>
                                            </InputGroupAddon> : ''}
                                          </InputGroup>
                                          : <Input
                                            type={item.type}
                                            disabled={!addNewBranch && item.key === 'email'}
                                            placeholder={"e.g. " + item.title}
                                            value={updatedProvider?.[item.key]}
                                            className="customInput"
                                            onChange={(e) => {
                                              UpdatedProvider({ ...updatedProvider, [item.key]: e.target.value, })
                                            }} />}
                                  {!addNewBranch && item.key === 'email' && <div
                                    color="primary"
                                    className="float-left mt-2"
                                    style={{ color: '#3498db', cursor: 'pointer', fontWeight: '500' }}
                                    onClick={() => UpdatePassword({ toggle: true, value: '' })}>Reset Password</div>
                                  }
                                  {!addNewBranch && item.key === 'email' && <div
                                    color="primary"
                                    className="float-left mt-2 ml-4"
                                    style={{ color: '#3498db', cursor: 'pointer', fontWeight: '500' }}
                                    onClick={() => UpdateEmail({ toggle: true, value: '' })}>Change Email</div>
                                  }
                                  {/* {!addNewBranch && item.key === 'number' && <div
                                    color="primary"
                                    className="float-left mt-2"
                                    style={{ color: '#3498db', cursor: 'pointer', fontWeight: '500' }}
                                    onClick={() => UpdateNumber({ toggle: true, value: '' })}>Change Number</div>
                                  } */}
                                </Colxx>
                              )
                            })}
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </TabPane>
                {accessTypes.includes(user?.accessType) && user?.accessType !== 'marketing' ? <TabPane tabId={2}>
                  <Card className="rounded">
                    <CardBody>
                      {[{
                        key: 'bankDetails', title: 'Bank Details section',
                        data: [
                          { key: 'IBAN', title: 'IBAN', parent: 'bankDetails', width: '10', type: 'text' },
                          { key: 'accountNumber', title: 'Account Number', parent: 'bankDetails', width: '10', type: 'number' },
                          { key: 'accountName', title: 'Account Name', parent: 'bankDetails', width: '10', type: 'text' },
                          { key: 'branch', title: 'Bank Name', parent: 'bankDetails', width: '10', type: 'text' },
                        ]
                      },
                      {
                        key: 'accountStatus', title: 'Account Status',
                        hide: user?.accessType === 'admin',
                        data: [
                          { key: 'joiningDate', title: 'Joining Date', width: '10', type: 'date' },
                          { key: 'expiryDate', title: 'Expiry Date', width: '10', type: 'date' },
                          { key: 'renewalDate', title: 'Renewal Date', width: '10', type: 'date' },
                        ]
                      },
                      {
                        key: 'pricingDetails', title: 'Pricing Details',
                        hide: user?.accessType === 'admin',
                        data: [
                          { key: 'commissionRate', title: 'Commission Rate', width: '10', type: 'number' },
                          { key: 'rejectionRate', title: 'Rejection Rate', width: '10', type: 'number' },
                          { key: 'subscriptionFees', title: 'Subscription Fees', width: '10', type: 'number' },
                        ]
                      }].map((rec, recIndex) => {
                        return !rec.hide && (
                          <Row key={recIndex} className="w-80">
                            <Colxx xxs="12" className="mb-2">
                              <h4>{rec.title}</h4>
                            </Colxx>
                            {rec.data.map((item, index) => {
                              return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                  <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                    <div>{item.title}</div>
                                  </div>
                                  {
                                    item.type === 'date' ?
                                      <DatePicker
                                        placeholderText="Select Date"
                                        value={updatedProvider?.[item.key] ? moment(updatedProvider?.[item.key]) : ''}
                                        popperClassName="redatepicker"
                                        customInput={<DatepickerInput />}
                                        selected={(moment(updatedProvider?.[item.key]).toDate())}
                                        onChange={date => {
                                          UpdatedProvider({ ...updatedProvider, [item.key]: moment(date).endOf('day').valueOf() })
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                      />
                                      : <Input
                                        type={item.type}
                                        placeholder={"e.g. " + item.title}
                                        value={item.parent ? updatedProvider?.[item.parent]?.[item.key] : updatedProvider?.[item.key]}
                                        className="customInput"
                                        onChange={(e) => {
                                          if (item.parent) {
                                            UpdatedProvider({ ...updatedProvider, [item.parent]: { ...updatedProvider?.[item?.parent], [item.key]: e.target.value, } })
                                          } else {
                                            UpdatedProvider({ ...updatedProvider, [item.key]: e.target.value, })
                                          }
                                        }} />}
                                </Colxx>
                              )
                            })}
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </TabPane> : ''}
                <TabPane tabId={3}>
                  <Subscriptions
                    selectedOutlet={selectedOutlet}
                    services={services}
                    subscriptions={updatedProvider?.subscriptions || {}}
                    update={(e) => {
                      UpdatedProvider({ ...updatedProvider, subscriptions: e })
                    }}
                  />

                </TabPane>
                {accessTypes.includes(user?.accessType) && user?.accessType !== 'marketing' ? <TabPane tabId={4}>
                  <Card className="rounded">
                    <CardBody>
                      {[{
                        key: 'sponsorship', title: 'Sponsorship',
                        data: [
                          {
                            key: 'sponsorPackage', title: 'Sponsorship package', width: '10', type: 'select',
                            data: [
                              { value: 'Platinum', label: 'Platinum', key: 'platinum' },
                              { value: 'Gold', label: 'Gold', key: 'gold' },
                              { value: 'Silver', label: 'Silver', key: 'silver' },
                            ]
                          },
                          { key: 'sponsorStartgDate', title: 'Start Date', width: '10', type: 'date' },
                          { key: 'sponsorEndDate', title: 'Expiry Date', width: '10', type: 'date' },
                        ]
                      },

                      ].map((rec, recIndex) => {
                        return !rec.hide && (
                          <Row key={recIndex} className="w-80">
                            <Colxx xxs="12" className="mb-2">
                              <h4>{rec.title}</h4>
                            </Colxx>
                            {rec.data.map((item, index) => {
                              return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                  <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                    <div>{item.title}</div>
                                  </div>
                                  {
                                    item.type === 'date' ?
                                      <DatePicker
                                        placeholderText="Select Date"
                                        value={updatedProvider?.[item.key] ? moment(updatedProvider?.[item.key]) : ''}
                                        popperClassName="redatepicker"
                                        customInput={<DatepickerInput />}
                                        selected={(moment(updatedProvider?.[item.key]).toDate())}
                                        onChange={date => {
                                          UpdatedProvider({ ...updatedProvider, [item.key]: moment(date).endOf('day').valueOf() })
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                      />
                                      : item.type === 'select' ?
                                        <Select
                                          components={{ Input: CustomSelectInput }}
                                          placeholder={item.title}
                                          value={item?.data?.map(rec => {
                                            return (updatedProvider?.[item.key] === rec.key) ?
                                              ({ ...rec, value: rec.value, label: rec.label, key: rec.key }) : ''
                                          })
                                          }
                                          options={item?.data?.map(rec => {
                                            return ({ ...rec, value: rec.value, label: rec.label, key: rec.key })
                                          })
                                          }
                                          onChange={val => {
                                            UpdatedProvider({ ...updatedProvider, [item.key]: val.key })
                                          }}
                                        />
                                        : <Input
                                          type={item.type}
                                          placeholder={"e.g. " + item.title}
                                          value={item.parent ? updatedProvider?.[item.parent]?.[item.key] : updatedProvider?.[item.key]}
                                          className="customInput"
                                          onChange={(e) => {
                                            if (item.parent) {
                                              UpdatedProvider({ ...updatedProvider, [item.parent]: { ...updatedProvider?.[item?.parent], [item.key]: e.target.value, } })
                                            } else {
                                              UpdatedProvider({ ...updatedProvider, [item.key]: e.target.value, })
                                            }
                                          }} />}
                                </Colxx>
                              )
                            })}
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </TabPane> : ''}
                <TabPane tabId={5}>
                  <Card className="rounded">
                    <CardBody>
                      {[{
                        key: 'vat', title: 'VAT Details',
                        data: [
                          { key: 'vatCertificate', title: 'VAT Certificate', width: '10', type: 'dropzone', downloadTemplate: true },
                          { key: 'trnNumber', title: 'Tax Registration Number (TRN)', width: '10', type: 'text' },
                        ]
                      },
                      ].map((rec, recIndex) => {
                        return !rec.hide && (
                          <Row key={recIndex} className="w-80">
                            <Colxx xxs="12" className="mb-2">
                              <h4>{rec.title}</h4>
                            </Colxx>
                            {rec.data.map((item, index) => {
                              return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4">
                                  <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'space-between' }}>
                                    <div>{item.title}</div>
                                    {item.downloadTemplate && <a
                                      href={vatDisclaimer}
                                      target="_blank"
                                      download={true}
                                      rel="noopener noreferrer"
                                      className="float-left mt-2"
                                      style={{ color: '#3498db', cursor: 'pointer', fontWeight: '500' }}
                                    >Download VAT Disclaimer Template</a>}
                                  </div>
                                  {item.type === 'dropzone' ?
                                    <InputGroup>
                                      <CustomInput
                                        type="file"
                                        id="exampleCustomFileBrowser4"
                                        name="customFile"
                                        label={updatedProvider?.[item.key]?.name}
                                        onChange={async ({ target: { files } }) => {
                                          const [{ name }] = files;
                                          UpdatedProvider({ ...updatedProvider, [item.key]: { name: name, files: files }, updated: true })
                                        }}
                                      />
                                      <InputGroupAddon addonType="append">
                                        <Button outline color="danger" onClick={() => {
                                          UpdatedProvider({ ...updatedProvider, [item.key]: {}, updated: true })
                                        }}>
                                          <i className="glyph-icon simple-icon-trash" />
                                        </Button>
                                      </InputGroupAddon>
                                      {updatedProvider?.[item.key]?.license ? <InputGroupAddon addonType="append">
                                        <Button outline color="primary" onClick={() => {
                                          window.open(updatedProvider?.[item.key]?.license, '_blank')
                                        }}>
                                          <i className="glyph-icon simple-icon-eye" />
                                        </Button>
                                      </InputGroupAddon> : ''}
                                    </InputGroup> : <Input
                                      type={item.type}
                                      placeholder={"e.g. " + item.title}
                                      value={item.parent ? updatedProvider?.[item.parent]?.[item.key] : updatedProvider?.[item.key]}
                                      className="customInput"
                                      onChange={(e) => {
                                        if (item.parent) {
                                          UpdatedProvider({ ...updatedProvider, [item.parent]: { ...updatedProvider?.[item?.parent], [item.key]: e.target.value, } })
                                        } else {
                                          UpdatedProvider({ ...updatedProvider, [item.key]: e.target.value, })
                                        }
                                      }} />}
                                </Colxx>
                              )
                            })}
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </TabPane>
                {accessTypes.includes(user?.accessType) ? <TabPane tabId={6}>
                  <Card className="rounded">
                    <CardBody>
                      {[{
                        key: 'settings', title: 'Settings',
                        data: [
                          {
                            key: 'cashPaymentDisabled', title: 'Disable Cash Payment',
                            description: 'If disabled, customers will not be able to pay with cash with this Service Provider',
                            width: '10', type: 'switch'
                          },
                          // {
                          //   key: 'acceptCard', title: 'Accept Card',
                          //   description: 'If disabled, customers will not be able to pay with card with this Service Provider',
                          //   width: '10', type: 'switch'
                          // },
                          // {
                          //   key: 'acceptApplePay', title: 'Accept Apple Pay',
                          //   description: 'If disabled, customers will not be able to pay with Apple Pay with this Service Provider',
                          //   width: '10', type: 'switch'
                          // },
                        ]
                      }].map((rec, recIndex) => {
                        return !rec.hide && (
                          <Row key={recIndex} className="w-80">
                            <Colxx xxs="12" className="mb-2">
                              <h4>{rec.title}</h4>
                            </Colxx>
                            {rec.data.map((item, index) => {
                              return !item.hide && (
                                <Colxx key={index} xxs="12" md={item.width} className="mb-4 d-flex align-items-center">
                                  <Switch
                                    className="custom-switch custom-switch-danger"
                                    checked={updatedProvider?.[item.key]}
                                    style={{ backgroundColor: '#000', borderColor: '#000' }}
                                    onChange={(switchCheckedPrimary) => {
                                      UpdatedProvider({ ...updatedProvider, [item.key]: switchCheckedPrimary })
                                    }}
                                  />
                                  <div className="mb-2 ml-4" style={{ justifyContent: 'space-between' }}>
                                    <div style={{ fontWeight: '600', fontSize: '16px' }}>{item.title}</div>
                                    <div style={{ fontSize: '14px' }}>{item.description}</div>
                                  </div>

                                </Colxx>
                              )
                            })}
                          </Row>
                        )
                      })}
                    </CardBody>
                  </Card>
                </TabPane> : ''}
              </TabContent>
              <Modal
                isOpen={changeEmail.toggle}
                backdrop="static">
                <ModalHeader >
                  Change Email
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Colxx xxs="12" >
                      <Label >
                        New Email
                      </Label>
                      <Input
                        type="text"
                        value={changeEmail.value}
                        onChange={(e) =>
                          UpdateEmail({ ...changeEmail, value: e.target.value })
                        }
                      />
                    </Colxx>
                  </Row>
                </ModalBody>
                <ModalFooter >
                  <Button color="danger" outline onClick={() => UpdateEmail(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" className="top-right-button" onClick={() => {
                    const changeProviderEmail = functions().httpsCallable('updateRecords');
                    changeProviderEmail({ uid: updatedProvider?.key, prop: { email: changeEmail.value } }).then(function (result) {
                      if (!result?.data?.error) {
                        firestore().collection('service_provider').doc(updatedProvider?.key).update({
                          email: changeEmail.value,
                          updated: Date.now()
                        }).then(() => {
                          UpdatedProvider({ ...updatedProvider, email: changeEmail.value })
                          UpdateEmail(false)
                        })
                      }
                    });
                  }}>
                    Change
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={resetPassword.toggle}
                backdrop="static">
                <ModalHeader >
                  Reset Password
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Colxx xxs="12" >
                      <Label >
                        Password
                      </Label>
                      <Input
                        type="password"
                        value={resetPassword?.value}
                        onChange={(e) =>
                          UpdatePassword({ ...resetPassword, value: e.target.value })
                        }
                      />
                    </Colxx>
                    <Colxx xxs="12" className="mt-4" >
                      <Label >
                        Confirm Password
                      </Label>
                      <Input
                        type="password"
                        value={resetPassword?.confirmPassword}
                        onChange={(e) =>
                          UpdatePassword({ ...resetPassword, confirmPassword: e.target.value })
                        }
                      />
                    </Colxx>
                  </Row>
                </ModalBody>
                <ModalFooter >
                  <Button color="danger" outline onClick={() => UpdatePassword(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" className="top-right-button" onClick={() => {

                    const changeProviderPassword = functions().httpsCallable('updateRecords');
                    changeProviderPassword({ uid: updatedProvider?.key, prop: { password: resetPassword.value } }).then(function (result) {
                      if (!result?.data?.error) {
                        UpdatePassword(false)
                      }
                    });
                  }}>
                    Reset
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={changeNumber.toggle}
                backdrop="static">
                <ModalHeader >
                  Change Phone Number
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Colxx xxs="12" >
                      <Label >
                        Phone Number
                      </Label>
                      <PhoneInput
                        regions={['middle-east', 'north-africa']}
                        inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                        className="customInput"
                        country={'ae'}
                        placeholder="e.g. +971xxxxxx"
                        value={changeNumber.value}
                        onChange={(value, data, event, formattedValue) =>
                          UpdateNumber({ ...changeNumber, value: formattedValue })
                        }
                      />
                    </Colxx>
                  </Row>
                </ModalBody>
                <ModalFooter >
                  <Button color="danger" outline onClick={() => UpdateNumber(false)}>
                    Cancel
                  </Button>
                  <Button color="primary" className="top-right-button" onClick={() => {
                    const changeProviderNumber = functions().httpsCallable('updateRecords');
                    changeProviderNumber({ uid: updatedProvider?.key, prop: { phoneNumber: changeNumber.value } }).then(function (result) {
                      if (!result?.data?.error) {
                        firestore().collection('service_provider').doc(updatedProvider?.key).update({
                          number: changeNumber.value,
                          updated: Date.now()
                        }).then(() => {
                          UpdatedProvider({ ...updatedProvider, number: changeNumber.value })
                          UpdateNumber(false)
                        })
                      } else {
                        createNotification("error", result?.data?.error?.errorInfo?.message)

                      }
                    });

                  }}>
                    Change
                  </Button>
                </ModalFooter>
              </Modal>
              <Button
                // disabled={checkIfDisabled(JSON.parse(JSON.stringify(updatedProvider)), ['name', 'details'])}
                color="primary"
                className="btn-shadow float-right mt-4"
                size="lg"
                onClick={() => {
                  setLoading(true)
                  submit()
                }}>
                Save Changes
              </Button>
              <Button
                color="danger"
                className="btn-shadow float-right m-4"
                size="lg"
                onClick={() => dismiss()}
              >
                Dismiss
              </Button>
            </Colxx>
          </Row>

        </Fragment >
      ))
  );

};

export default Outlets;