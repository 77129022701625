import moment from 'moment';
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import ReactTable from "react-table";
import { Button, Card, CardBody, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { Colxx } from '../../../../components/common/CustomBootstrap';
import { firestore } from '../../../../helpers/Firebase';
import { filterAndSortObjectByCategory } from '../../../../helpers/Utils';
const CustomSelectInput = props => {
    var customProps = Object.assign({}, props);
    delete customProps.autoCorrect;
    delete customProps.autoCapitalize;
    return <components.Input {...customProps} />;

};
const deviceWidth = () => {
    const width = window.innerWidth;
    const breakpoint = 820;
    return width < breakpoint ? 'mobile' : 'desktop';
}
let options = []
let startDuration = '00:15';
for (var i = 0; i < 26; i++) {
    var obj = { label: startDuration, value: startDuration, key: i + 1 };
    startDuration = moment(startDuration, 'HH:mm').add('15', 'minutes').format('HH:mm');
    options.push(obj);
}
const CarWash = ({
    selectedProvider,
    allActiveServices,
    activeServices,
    services,
    modifiers,
    servicesDescs,
    update }) => {

    const [service, setService] = useState({})
    const [editService, setEditService] = useState(false)
    const [addService, setAddService] = useState(false)
    const [washType, setWashType] = useState('normalWash');

    const [saveDefultService, setSaveDefultService] = useState(false)
    const [serviceDescriptions, setServiceDescriptions] = useState({})
    const [serviceModifiers, setServiceModifiers] = useState([])
    const [newService, setNewService] = useState({})

    const handleServicePriceChange = (value, modifier, type) => {
        let updatedService = JSON.parse(JSON.stringify(newService));
        let serviceKey = modifier.key;
        if (!updatedService[serviceKey]) {
            updatedService[serviceKey] = {};
        }
        updatedService[serviceKey][type] = value
        setNewService(updatedService);
    };

    // let handleCreate = (inputValue, type) => {

    //     let newDescriptionObject = JSON.parse(JSON.stringify(serviceDescriptions))
    //     if (newDescriptionObject) {
    //         newDescriptionObject[type] = serviceDescriptions?.[type] || []
    //     } else {
    //         newDescriptionObject = { [type]: serviceDescriptions?.[type] || [] }
    //     }
    //     (newDescriptionObject?.[type]).push(inputValue)
    //     setServiceDescriptions(newDescriptionObject)
    // };

    let saveAsDefultSettings = () => {
        if (selectedProvider?.defultSettings?.activeServices) {
            selectedProvider.defultSettings.activeServices[service.key] = newService
        } else {
            selectedProvider.defultSettings = { activeServices: { [service.key]: newService } }
        }
        firestore().collection('service_provider').doc(selectedProvider.key).update({
            [`defultSettings.servicesDescs.${[service.key]}`]:
                serviceDescriptions

        }).then(() => {
            firestore().collection('service_provider').doc(selectedProvider.key).update({
                [`defultSettings.activeServices.${[service.key]}`]:
                    newService

            }).then(() => {
                update({ loading: false, selectedProvider }, () => {
                    UpdateService()
                })
            })
        })
    }
    let copyService = () => {

        if (selectedProvider?.defultSettings?.servicesDescs?.[service.key]) {
            let newCurrentServiceDescription = selectedProvider?.defultSettings?.servicesDescs?.[service.key];
            setServiceDescriptions(newCurrentServiceDescription)
        }
        if (selectedProvider?.defultSettings?.activeServices?.[service.key]) {
            let ser = selectedProvider.defultSettings.activeServices[service.key]
            let newServiceModifiers = []
            Object.keys(ser).map(item => {
                return newServiceModifiers.push(modifiers[item])
            })
            setNewService(ser)
            setServiceModifiers(newServiceModifiers)
        }
    }

    let activeServicesColumns = () => {
        let newServiceModifiers = []
        return ([

            {
                width: deviceWidth() === 'desktop' ? 150 : 100,
                Header: "Name",
                accessor: "name",
                Cell: props => <p className="washerfieldText">{props.value}</p>
            },
            {
                width: 150,
                Header: "Arabic Name",
                accessor: "name_ar",
                show: deviceWidth() === 'desktop' ? true : false,

                Cell: props => <p className="washerfieldText">{props.value}</p>
            },
            {
                Header: "Modifiers",
                accessor: "modifiers",
                show: deviceWidth() === 'desktop' ? true : false,
                Cell: props => <div className="washerfieldText">{props.value}</div>
            },
            {
                width: deviceWidth() === 'desktop' ? 95 : 90,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="danger"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            delete servicesDescs[props.original.key];
                            delete activeServices[props.original.key];
                            update({ activeServices, servicesDescs });
                        }}>
                        Remove
                    </Button>
                </div>
            },
            {
                width: deviceWidth() === 'desktop' ? 70 : 75,
                accessor: "edit",
                Cell: props => <div className="washerfieldText">
                    <Button
                        color="primary"
                        size="sm"
                        outline
                        className="top-right-button"
                        onClick={() => {
                            props.original.serviceModifiers.map(item => {
                                return (
                                    (activeServices[props.original.key][modifiers[item].key]) ?
                                        newServiceModifiers.push(modifiers[item]) : ''
                                )
                            });
                            setEditService(true)
                            setService(props.original)
                            setServiceDescriptions(servicesDescs?.[props.original.key] ? JSON.parse(JSON.stringify(servicesDescs[props.original.key])) : {})
                            setServiceModifiers(newServiceModifiers)
                            setNewService(JSON.parse(JSON.stringify(activeServices[props.original.key])))
                        }}>
                        Edit
                    </Button>
                </div>
            }
        ])
    }
    let UpdateService = () => {
        activeServices[service.key] = newService
        servicesDescs[service.key] = serviceDescriptions

        setAddService(false)
        setEditService(false)
        setService({})
        setServiceModifiers([])
        setNewService({})
        setServiceDescriptions({})
        update({ activeServices, servicesDescs })
    }
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span style={{ fontSize: '14px' }}>{data?.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    return (
        <Row>
            <Colxx xxs="12" md="12" >
                <Card className="rounded h-100">
                    <CardBody>
                        <h4>Services</h4>
                        <p>Enable available services at your store and set prices for different vechicles</p>
                        <Select
                            components={{ Input: CustomSelectInput }}
                            className="react-select"
                            classNamePrefix="react-select"
                            name="form-field-name"
                            options={services && Object.values(services).filter(item => {
                                return !activeServices[item.key]
                            })}
                            value={""}
                            onChange={val => {
                                setService(val)
                                setAddService(true)
                            }}
                        />
                        {

                            allActiveServices && allActiveServices.length ? <ReactTable
                                data={allActiveServices?.sort((a, b) => a.index - b.index)}
                                columns={activeServicesColumns()}
                                defaultPageSize={allActiveServices.length}
                                pageSize={allActiveServices.length}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPagination={false}
                                className='servicesTable'
                            /> :
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '50px 0' }}>No Services</div>


                        }
                    </CardBody>
                </Card>
            </Colxx>
            <Modal
                size='lg'
                isOpen={addService || editService}
                backdrop="static"
            >
                {/* {this.state.loading && <div className="loading" />} */}
                <ModalHeader >Edit Modifiers price and duration</ModalHeader>
                <ModalBody>
                    <Row className="d-flex align-items-center">
                        <Colxx xxs="12" md="6" >
                            <h2 >{service?.name}</h2>
                        </Colxx>
                        <Colxx xxs="12" md="6" >
                            {selectedProvider?.defultSettings?.activeServices?.[service.key] ?
                                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button
                                        outline
                                        color="primary"
                                        style={{ fontSize: '8px', marginBottom: '.5rem', fontWeight: '600', padding: '2px 10px', borderRadius: '10px' }}
                                        onClick={() => copyService()}>Fill with defult settings</Button>
                                    <Button disabled={serviceModifiers?.find((item) => {
                                        return !(newService && newService?.[item.key] && newService?.[item.key]?.duration && newService?.[item.key]?.price)
                                    })}
                                        outline
                                        color="success"
                                        style={{ fontSize: '8px', marginBottom: '.5rem', fontWeight: '600', marginLeft: '18px', padding: '2px 10px', borderRadius: '10px' }}
                                        onClick={() => setSaveDefultService(true)}>Save as new defult settings</Button>
                                </div>
                                : <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Button disabled={serviceModifiers?.find((item) => {
                                        return !(newService && newService?.[item.key] && newService?.[item.key]?.duration && newService?.[item.key]?.price)
                                    })} outline color="success" style={{ fontSize: '8px', marginBottom: '.5rem', fontWeight: '600', padding: '2px 10px', borderRadius: '10px' }} onClick={() => setSaveDefultService(true)}>Save as defult settings</Button>
                                </div>
                            }
                        </Colxx>
                    </Row>
                    {service?.type === 'recovery' ? <>
                        <Row>
                            <Colxx xxs="12" className="mt-4" >
                                <Label >Recovery Types</Label>
                                <Select
                                    formatGroupLabel={formatGroupLabel}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    styles={{ width: '50%' }}
                                    placeholder="Add types"
                                    name="form-field-name"

                                    options={[{ key: 'normal', label: 'Normal', value: 'Normal' }, { key: 'box', label: 'Box', value: 'Box' }, { key: 'flat', label: 'Flat', value: 'Flat' }]?.filter(item => {
                                        const isOptionUnavailable = Object.keys(newService || {})?.includes(item.key);
                                        return !isOptionUnavailable;

                                    })
                                    }
                                    onChange={(e) => {
                                        const modifierKey = e.key;
                                        const updatedNewService = { ...newService };

                                        updatedNewService[modifierKey] = '';
                                        setNewService(updatedNewService);


                                    }}
                                />
                                {
                                    service &&
                                    service.key &&
                                    (
                                        <Row>
                                            <Colxx xxs="12" md="12" >
                                                <Table className="timingsTable mt-4">
                                                    <thead>
                                                        <tr>
                                                            <th className="dayCell">Recovery Type</th>
                                                            <th className="dayCell">Plate Number</th>
                                                            <th className="actionCell"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(newService)?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="dayCell" style={{ verticalAlign: 'middle', textTransform: 'capitalize' }}>{item}</td>
                                                                <td className="priceCell" style={{ verticalAlign: 'middle' }}>
                                                                    <Input
                                                                        className="customInput"
                                                                        value={newService?.[item] || ''}
                                                                        placeholder={`e.g. Dubai A 12345`}
                                                                        type={'text'}
                                                                        onChange={(e) => {
                                                                            let newActiveService = JSON.parse(JSON.stringify(newService));
                                                                            newActiveService[item] = e.target.value

                                                                            setNewService(newActiveService);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className="actionCell" style={{ verticalAlign: 'middle' }}>
                                                                    <i className="simple-icon-close" style={{ cursor: 'pointer' }} onClick={() => {
                                                                        let newActiveService = JSON.parse(JSON.stringify(newService));
                                                                        delete newActiveService[item]

                                                                        setNewService(newActiveService);

                                                                    }} />

                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>

                                            </Colxx>
                                        </Row>
                                    )
                                }

                            </Colxx>
                        </Row>
                    </> : <>
                        <Row>
                            {service?.washingService && <Colxx xxs="12" className="mt-2 mb-2">
                                {console.log('service', service)}
                                <Button color="primary" className='mr-2' size='md' outline={washType !== 'normalWash'} onClick={() => {
                                    setWashType('normalWash')
                                }}>Pressure Wash</Button>
                                <Button color="primary" className='mr-2' size='md' outline={washType !== 'waterlessWash'} onClick={() => {
                                    setWashType('waterlessWash')
                                }}>Waterless Wash</Button>
                                <Button color="primary" size='md' outline={washType !== 'steamWash'} onClick={() => {
                                    setWashType('steamWash')
                                }}>Steam Wash</Button>
                            </Colxx>}
                            {[
                                { key: 'description', title: 'Description', hide: washType !== 'normalWash' },
                                { key: 'description_ar', title: 'Arabic Description', hide: washType !== 'normalWash' },
                                { key: 'waterlessDescription', title: 'Waterless Description', hide: washType !== 'waterlessWash' },
                                { key: 'waterlessDescription_ar', title: 'Waterless Arabic Description', hide: washType !== 'waterlessWash' },
                                { key: 'steamWashDescription', title: 'Steam Wash Description', hide: washType !== 'steamWash' },
                                { key: 'steamWashDescription_ar', title: 'Steam Wash Arabic Description', hide: washType !== 'steamWash' },
                            ].map(rec => {
                                return !rec.hide && (
                                    <Colxx xxs="6" className="mt-2">
                                        <Label >{rec.title}</Label>
                                        <Select
                                            components={{ Input: CustomSelectInput }}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            name="form-field-name"
                                            value={''}
                                            options={(service?.[rec.key] || [])?.map(item => ({ label: item, value: item, key: item }))?.filter(item => {
                                                const isOptionUnavailable = serviceDescriptions?.[rec.key]?.includes(item.key);
                                                return !isOptionUnavailable;
                                            })}
                                            placeholder={`Select ${rec.title}`}
                                            // onCreateOption={(inputValue) => handleCreate(inputValue, rec.key)}
                                            onChange={(e) => {
                                                console.log('e', e)
                                                let newDescriptionObject = JSON.parse(JSON.stringify(serviceDescriptions))
                                                if (newDescriptionObject) {
                                                    newDescriptionObject[rec.key] = serviceDescriptions?.[rec.key] || []
                                                } else {
                                                    newDescriptionObject = { [rec.key]: serviceDescriptions?.[rec.key] || [] }
                                                }
                                                (newDescriptionObject?.[rec.key]).push(e.value)
                                                setServiceDescriptions(newDescriptionObject)
                                            }}
                                        />
                                        {serviceDescriptions?.[rec.key]?.length ? <div className="mt-2"
                                            style={{
                                                minHeight: 'auto',
                                                borderRadius: '3px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                flexWrap: 'wrap',
                                            }}>
                                            {serviceDescriptions?.[rec.key]?.map((desc, index) => {
                                                return (
                                                    <div key={index} style={{
                                                        margin: '5px',
                                                        padding: ' 5px 10px',
                                                        border: '1px solid #d7d7d7',
                                                        borderRadius: '8px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <div style={{ marginRight: '8px' }}>{desc}</div>
                                                        <i style={{ cursor: 'pointer' }} className="simple-icon-close" onClick={() => {
                                                            let newDescs = JSON.parse(JSON.stringify(serviceDescriptions)) || {}
                                                            newDescs[rec.key] = (newDescs?.[rec.key]).filter((deletedItem) => {
                                                                return (deletedItem !== desc)
                                                            })
                                                            setServiceDescriptions(newDescs)
                                                        }} />
                                                    </div>
                                                )
                                            })}
                                        </div> : ""
                                        }
                                    </Colxx>
                                )
                            })}
                        </Row>
                        <Row>
                            <Colxx xxs="12" className="mt-4" >
                                <Label >Modifiers</Label>
                                <Select
                                    formatGroupLabel={formatGroupLabel}
                                    components={{ Input: CustomSelectInput }}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    styles={{ width: '50%' }}
                                    placeholder="Add Modifiers"
                                    name="form-field-name"
                                    options={Object.values(filterAndSortObjectByCategory(modifiers, Object.values(modifiers).filter(item => {
                                        const isOptionUnavailable =
                                            (newService?.[item.key] && (washType === 'normalWash' ? (newService[item.key].price || newService[item.key].duration) : washType === 'waterlessWash' ? (newService[item.key].waterlessPrice || newService[item.key].waterlessDuration) : (newService[item.key].steamWashPrice || newService[item.key].steamWashDuration)));
                                        return isOptionUnavailable;
                                    }).map(i => i.key)))}
                                    onChange={(e) => {
                                        const modifierKey = e.key;

                                        // Create a copy of the newService object
                                        const updatedNewService = { ...newService };

                                        if (washType === 'normalWash') {
                                            // Check if the modifier already exists in newService
                                            if (updatedNewService[modifierKey]) {
                                                // Merge the existing values with the new values
                                                updatedNewService[modifierKey] = {
                                                    ...updatedNewService[modifierKey],
                                                    price: e.price,
                                                    duration: e.duration
                                                };
                                            } else {
                                                // If it doesn't exist, add the new values
                                                updatedNewService[modifierKey] = { price: e.price, duration: e.duration };
                                            }
                                        } else if (washType === 'waterlessWash') {
                                            // Similar logic for the 'waterlessWash' case
                                            if (updatedNewService[modifierKey]) {
                                                updatedNewService[modifierKey] = {
                                                    ...updatedNewService[modifierKey],
                                                    waterlessPrice: e.price,
                                                    waterlessDuration: e.duration
                                                };
                                            } else {
                                                updatedNewService[modifierKey] = {
                                                    waterlessPrice: e.price,
                                                    waterlessDuration: e.duration
                                                };
                                            }
                                        } else {
                                            // Similar logic for the 'waterlessWash' case
                                            if (updatedNewService[modifierKey]) {
                                                updatedNewService[modifierKey] = {
                                                    ...updatedNewService[modifierKey],
                                                    steamWashPrice: e.price,
                                                    steamWashDuration: e.duration
                                                };
                                            } else {
                                                updatedNewService[modifierKey] = {
                                                    steamWashPrice: e.price,
                                                    steamWashDuration: e.duration
                                                };
                                            }
                                        }

                                        // Update the serviceModifiers state
                                        const updatedServiceModifiers = [...serviceModifiers];
                                        const existingModifierIndex = updatedServiceModifiers.findIndex(item => item.key === modifierKey);

                                        if (existingModifierIndex !== -1) {
                                            updatedServiceModifiers[existingModifierIndex] = e;
                                        } else {
                                            updatedServiceModifiers.push(e);
                                        }

                                        setServiceModifiers(updatedServiceModifiers);
                                        setNewService(updatedNewService);
                                    }}
                                />
                                {
                                    service &&
                                    service.key &&
                                    (
                                        <Row>
                                            <Colxx xxs="12" md="12" >
                                                <Table className="timingsTable mt-4">
                                                    <thead>
                                                        <tr>
                                                            <th className="dayCell">Modifier</th>
                                                            <th className="priceCell">Price</th>
                                                            <th className="durationCell">Duration</th>
                                                            <th className="actionCell"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.from(new Set(serviceModifiers?.filter(item => {
                                                            const isOptionAvailable =
                                                                (washType === 'normalWash' ? (newService?.[item.key]?.duration) : washType === 'waterlessWash' ? (newService?.[item.key]?.waterlessDuration) : (newService?.[item.key]?.steamWashDuration))

                                                            return isOptionAvailable;
                                                        }).map(item => item.category))).map((category, categoryIndex) => (
                                                            <React.Fragment key={categoryIndex}>
                                                                {/* Section Title */}
                                                                <tr>
                                                                    <th colSpan="4" className="sectionTitle" style={{
                                                                        fontSize: ' 16px',
                                                                        textTransform: 'capitalize'
                                                                    }}>{category}</th>
                                                                </tr>

                                                                {/* Items within the current category */}
                                                                {serviceModifiers
                                                                    .filter(item => item.category === category)
                                                                    .filter(item => {
                                                                        const isOptionAvailable =
                                                                            (washType === 'normalWash' ? (newService?.[item.key]?.duration) : washType === 'waterlessWash' ? (newService?.[item.key]?.waterlessDuration) : (newService?.[item.key]?.steamWashDuration))

                                                                        return isOptionAvailable;
                                                                    }).map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="dayCell" style={{ verticalAlign: 'middle' }}>{item.name}</td>

                                                                            {[
                                                                                { key: 'price', hide: washType !== 'normalWash' },
                                                                                { key: 'duration', type: 'select', hide: washType !== 'normalWash' },
                                                                                { key: 'waterlessPrice', hide: washType !== 'waterlessWash' },
                                                                                { key: 'waterlessDuration', type: 'select', hide: washType !== 'waterlessWash' },
                                                                                { key: 'steamWashPrice', hide: washType !== 'steamWash' },
                                                                                { key: 'steamWashDuration', type: 'select', hide: washType !== 'steamWash' },
                                                                            ].map(rec => {
                                                                                return !rec.hide && (
                                                                                    (rec.type === 'select' ? <td className="durationCell" style={{ verticalAlign: 'middle' }}>
                                                                                        <Select
                                                                                            components={{ Input: CustomSelectInput }}
                                                                                            className="react-select"
                                                                                            classNamePrefix="react-select"
                                                                                            name="form-field-name"
                                                                                            value={newService && newService[item.key] && newService?.[item.key]?.[rec.key] ? { value: newService[item.key]?.[rec.key], label: newService[item.key]?.[rec.key], key: index } : ''}
                                                                                            placeholder="e.g. 00:15"
                                                                                            onChange={(e) => handleServicePriceChange(e?.value, item, rec.key)}
                                                                                            options={options}
                                                                                        />
                                                                                    </td> : <td className="priceCell" style={{ verticalAlign: 'middle' }}>
                                                                                        <Input
                                                                                            className="customInput"
                                                                                            value={newService && newService[item.key] ? newService[item.key]?.[rec.key] : ''}
                                                                                            placeholder={`e.g. ${rec.key === 'price' ? '10 AED' : '00:15'}`}
                                                                                            type={rec.key === 'price' ? 'number' : 'text'}
                                                                                            onChange={(e) => handleServicePriceChange(e.target.value, item, rec.key)}
                                                                                        />
                                                                                    </td>)
                                                                                )
                                                                            })}

                                                                            <td className="actionCell" style={{ verticalAlign: 'middle' }}>
                                                                                <i
                                                                                    className="simple-icon-close"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        let newServiceModifiers = JSON.parse(JSON.stringify(serviceModifiers));
                                                                                        let newActiveService = JSON.parse(JSON.stringify(newService));

                                                                                        if (washType === 'normalWash') {
                                                                                            // Check if it's a 'normalWash' and delete 'price' and 'duration'
                                                                                            if (newActiveService[item.key]) {
                                                                                                delete newActiveService[item.key].price;
                                                                                                delete newActiveService[item.key].duration;
                                                                                            }
                                                                                        } else if (washType === 'waterlessWash') {
                                                                                            if (newActiveService[item.key]) {
                                                                                                delete newActiveService[item.key].waterlessPrice;
                                                                                                delete newActiveService[item.key].waterlessDuration;
                                                                                            }
                                                                                        } else {
                                                                                            if (newActiveService[item.key]) {
                                                                                                delete newActiveService[item.key].steamWashPrice;
                                                                                                delete newActiveService[item.key].steamWashDuration;
                                                                                            }
                                                                                        }
                                                                                        if (Object.keys(newActiveService?.[item?.key] || {}).length === 0) {
                                                                                            delete newActiveService[item.key]; // Remove the item if it's an empty object
                                                                                            const indexToRemove = newServiceModifiers.findIndex(modifier => modifier.key === item.key);
                                                                                            if (indexToRemove !== -1) {
                                                                                                newServiceModifiers.splice(indexToRemove, 1);
                                                                                            }
                                                                                        }
                                                                                        // Here, we are updating the corresponding item in newServiceModifiers
                                                                                        const indexToUpdate = newServiceModifiers.findIndex(modifier => modifier.key === item.key);

                                                                                        if (indexToUpdate !== -1) {
                                                                                            newServiceModifiers[indexToUpdate] = {
                                                                                                ...newServiceModifiers[indexToUpdate], // Copy the existing object
                                                                                                ...newActiveService[item.key] // Update with new values
                                                                                            };
                                                                                            const modifierToCheck = newServiceModifiers[indexToUpdate];
                                                                                            if (
                                                                                                !modifierToCheck.price &&
                                                                                                !modifierToCheck.duration &&
                                                                                                !modifierToCheck.waterlessPrice &&
                                                                                                !modifierToCheck.waterlessDuration &&
                                                                                                !modifierToCheck.steamWashPrice &&
                                                                                                !modifierToCheck.steamWashDuration
                                                                                            ) {
                                                                                                newServiceModifiers.splice(indexToUpdate, 1); // Remove the item if conditions are met
                                                                                            }
                                                                                        }

                                                                                        setServiceModifiers(newServiceModifiers);
                                                                                        setNewService(newActiveService);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </tbody>
                                                </Table>

                                            </Colxx>
                                        </Row>
                                    )
                                }

                            </Colxx>
                        </Row>
                    </>}
                </ModalBody>
                <ModalFooter >
                    <Button color="danger" outline onClick={() => {
                        setService({})
                        setEditService(false)
                        setAddService(false)
                        setServiceModifiers([])
                        setNewService({})

                    }}>Cancel</Button>
                    <Button color="primary"
                        disabled={serviceModifiers?.find((item) => {
                            return !(newService?.[item?.key] && ((newService[item.key].duration && newService[item.key].price) || (newService[item.key].waterlessPrice && newService[item.key].waterlessDuration) || (newService[item.key].steamWashPrice && newService[item.key].steamWashDuration)))
                        })}
                        className="top-right-button"
                        onClick={() => {

                            if (selectedProvider?.defultSettings?.activeServices?.[service?.key]) {
                                UpdateService()
                            } else {
                                setSaveDefultService(true)
                            }
                        }}
                    >{editService ? 'Save Service' : 'Add Service'}</Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={saveDefultService}
                backdrop="static">
                <ModalHeader >Defult Settings</ModalHeader>
                <ModalBody>
                    <Row>
                        <Colxx xxs="12" >
                            <Label >Do you want to save curent setting as defult settings?</Label>
                        </Colxx>
                    </Row>
                </ModalBody>
                <ModalFooter >
                    <Button color="danger" className="top-right-button" onClick={() => {
                        setSaveDefultService(false)
                        UpdateService()
                    }}>Don't Save</Button>
                    <Button color="primary" className="top-right-button" onClick={() => {
                        setSaveDefultService(false)
                        saveAsDefultSettings()
                        update({ loading: true })
                    }}>Save</Button>
                </ModalFooter>
            </Modal>
        </Row >
    );
};

export default CarWash;