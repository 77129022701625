import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Input, Row } from 'reactstrap';
import { Colxx } from '../../../components/common/CustomBootstrap';

const RecoveryPricing = ({ update, defaultValue }) => {
    const [recoveryPricing, setRecoveryPricing] = useState(defaultValue || {});
    useEffect(() => {
        setRecoveryPricing(defaultValue);
    }, [defaultValue]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecoveryPricing({ ...recoveryPricing, [name]: value });
        update({ ...recoveryPricing, [name]: value });
    }
    const handleDistanceVariationChange = (e, index) => {
        const { name, value } = e.target;
        let distanceVariations = recoveryPricing.distanceVariations;
        distanceVariations[index][name] = value;
        setRecoveryPricing({ ...recoveryPricing, distanceVariations });
        update({ ...recoveryPricing, distanceVariations });
    }
    const handleDateChange = (date, item, index, type) => {
        let rushHours = recoveryPricing?.rushHours || [];
        if (type === 'start') {
            rushHours[index].startTime = moment(date).valueOf();
        } else {
            rushHours[index].endTime = moment(date).valueOf();
        }
        setRecoveryPricing({ ...recoveryPricing, rushHours });
        update({ ...recoveryPricing, rushHours });
    }
    const addTimings = () => {
        let rushHours = recoveryPricing?.rushHours || [];
        let optimizedMoment = moment("1955-01-05T" + moment().format('HH:mm'))
        rushHours.push({ startTime: optimizedMoment.startOf('day').valueOf(), endTime: optimizedMoment.endOf('day').valueOf() });
        setRecoveryPricing({ ...recoveryPricing, rushHours });
        update({ ...recoveryPricing, rushHours });
    }
    const removeTimings = (item, index) => {
        let rushHours = recoveryPricing?.rushHours || [];
        rushHours.splice(index, 1);
        setRecoveryPricing({ ...recoveryPricing, rushHours });
        update({ ...recoveryPricing, rushHours });
    }
    const CustomInput = React.forwardRef((props, ref) => {
        return (
            <input
                onClick={props.onClick}
                value={props.value}
                type="text"
                className="customDatePicker"
                style={{ margin: 0, marginRight: '10px' }}
                readOnly={true}
                ref={ref}
            />
        )
    })

    return (
        <div>
            <Row className={'mb-4'}>
                {
                    [
                        { key: 'normal', value: 'Normal Recovery Price' },
                        { key: 'flat', value: 'Flat Recovery Price' },
                        { key: 'box', value: 'Box Recovery Price' },
                        { key: 'emergency', value: 'Emergency' }
                    ].map((item, index) => {
                        return (
                            <Colxx key={index} >
                                <div className='mb-2' >{item.value}</div>
                                <Input type="number" placeholder="Base Price"
                                    name={item.key}
                                    value={recoveryPricing?.[item.key]}
                                    onChange={handleChange}
                                />
                            </Colxx>
                        );
                    })
                }
            </Row>
            <div className={'mb-4'}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='mb-2' >Distance Pricing</div>
                    <Button color="primary" size="sm" onClick={() => {
                        let newVariation = { minDistance: '', maxDistance: '', priceRate: '' };
                        setRecoveryPricing({
                            ...recoveryPricing,
                            distanceVariations: recoveryPricing?.distanceVariations ? [...recoveryPricing.distanceVariations, newVariation] : [newVariation]
                        });
                        update({
                            ...recoveryPricing,
                            distanceVariations: recoveryPricing?.distanceVariations ? [...recoveryPricing.distanceVariations, newVariation] : [newVariation]
                        });

                    }}>
                        Add Distance Pricing
                    </Button>
                </div>
                {recoveryPricing?.distanceVariations?.map((item, index) => {
                    return (
                        <Row key={index} className={'mb-3'}>
                            <Colxx >
                                <div className='mb-2' >Min Distance</div>
                                <Input type="number" placeholder="Min Distance"
                                    name="minDistance"
                                    value={item.minDistance}
                                    onChange={(e) => handleDistanceVariationChange(e, index)}

                                />
                            </Colxx>
                            <Colxx >
                                <div className='mb-2' >Max Distance</div>
                                <Input type="number" placeholder="Max Distance"
                                    name="maxDistance"
                                    value={item.maxDistance}
                                    onChange={(e) => handleDistanceVariationChange(e, index)}
                                />
                            </Colxx>
                            <Colxx >
                                <div className='mb-2' >Price Rate</div>
                                <Input type="number" placeholder="Price Rate/Distance" name="priceRate" value={item.priceRate} onChange={(e) => handleDistanceVariationChange(e, index)} />
                            </Colxx>
                            <Colxx xxs="2" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                <Button outline color="danger" size="sm" onClick={() => {
                                    let distanceVariations = recoveryPricing.distanceVariations;
                                    distanceVariations.splice(index, 1);
                                    setRecoveryPricing({ ...recoveryPricing, distanceVariations });
                                    update({ ...recoveryPricing, distanceVariations });
                                }
                                }> <i className="simple-icon-trash" /></Button>

                            </Colxx>
                        </Row>
                    );
                })}

            </div>
            <div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='mb-2' >Rush hours Timings</div>
                    <Button color="primary" size="sm" onClick={() => {
                        addTimings()
                    }}>
                        Add Timings
                    </Button>
                </div>
                {

                    recoveryPricing?.rushHours?.map((item, index) => {
                        return (
                            <Row key={index} className={'mb-3'}>
                                <Colxx >
                                    <div className='mb-2' >Start Time</div>
                                    <DatePicker
                                        customInput={<CustomInput />}
                                        selected={(item?.startTime ? moment(item?.startTime).toDate() : moment().endOf('day').toDate())}
                                        className="customDatePicker"
                                        showTimeSelectOnly
                                        onChange={date => handleDateChange(date, item, index, 'start')}
                                        showTimeSelect
                                        dateFormat="hh:mm a"
                                        timeFormat="hh:mm a"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        placeholderText={"Start"}
                                    />
                                </Colxx>
                                <Colxx >
                                    <div className='mb-2'>End Time</div>
                                    <DatePicker
                                        customInput={<CustomInput />}
                                        selected={(item?.endTime ? moment(item?.endTime).toDate() : moment().endOf('day').toDate())}
                                        className="customDatePicker"
                                        showTimeSelectOnly
                                        onChange={date => { handleDateChange(date, item, index, 'end') }}
                                        showTimeSelect
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="hh:mm a"
                                        timeFormat="hh:mm a"
                                        placeholderText={"End"}
                                    />
                                </Colxx>
                                <Colxx >
                                    <div className='mb-2'>Price Rate</div>
                                    <Input type="number" name="priceRate" value={item.priceRate} onChange={(e) => {
                                        let rushHours = recoveryPricing.rushHours;
                                        rushHours[index].priceRate = e.target.value;
                                        setRecoveryPricing({ ...recoveryPricing, rushHours });
                                        update({ ...recoveryPricing, rushHours });
                                    }
                                    } />

                                </Colxx>

                                <Colxx xxs="2" style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                    <Button outline color="danger" size="sm" onClick={() => {
                                        removeTimings(item, index)
                                    }
                                    }> <i className="simple-icon-trash" /></Button>
                                </Colxx>
                            </Row>

                        )
                    })
                }
            </div>
        </div>
    );
};

export default RecoveryPricing;