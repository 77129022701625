import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { Colxx } from "../../../../components/common/CustomBootstrap";
import { firestore, functions } from '../../../../helpers/Firebase';

import moment from "moment";
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";
import { createNotification } from "../../../../helpers/Utils";

const Voucher = ({
    selectedVoucher,
    dismiss
}) => {

    const [updatedVoucher, UpdateVoucher] = useState(JSON.parse(JSON.stringify(selectedVoucher)))
    const [isLoading, setLoading] = useState(false)
    console.log('updatedVoucher', updatedVoucher);
    const handleNumber = (e) => {
        return `+${e.replace(/\D/g, '')}`
    }

    const submit = async (voucher) => {
        setLoading(true)
        let number = handleNumber(voucher?.customer)
        let getCustomer = functions().httpsCallable('getUserByPhoneNumber')
        let result = await getCustomer({ phoneNumber: number })
        if (!result?.data?.error) {
            let userID = result?.data?.user
            let userSnap = await firestore().collection('users').doc(userID).get();
            let user_fcm_token = userSnap?.data()?.fcm_token;

            if (voucher?.numberOfSplits > 1) {
                for (let i = 0; i < voucher?.numberOfSplits; i++) {
                    let ref = firestore().collection('users').doc(userID).collection('walletLog').doc()

                    let splitVoucher = JSON.parse(JSON.stringify(voucher))
                    splitVoucher.amount = Number(voucher.amount / voucher.numberOfSplits).toFixed(2)
                    splitVoucher.key = ref.id
                    splitVoucher.updated = Date.now()
                    splitVoucher.userID = userID
                    splitVoucher.created = Date.now()
                    splitVoucher.type = 'voucher'
                    delete splitVoucher.numberOfSplits
                    delete splitVoucher.addNew
                    await ref.set(splitVoucher, { merge: true })
                }
                try {
                    await firestore().collection('notifications').add({
                        user: userID,
                        to: user_fcm_token,
                        title: voucher?.description === 'Voucher' ? 'Congratulations! You Have a New Voucher' : 'Compensation Granted',
                        body: voucher?.description === 'Voucher' ? `Great news! You've received a voucher worth ${voucher.amount} AED. Check your account to redeem it now.` :
                            `We apologize for any inconvenience. You've been compensated with a voucher worth ${voucher.amount} AED. Please check your account to redeem it.`,
                        read: false
                    })
                } catch (error) {
                    console.log("error notifications RejectedBody", error);
                }
                setLoading(false)
                dismiss()
                createNotification('success', 'Vouchers created successfully')
            } else {
                let id = voucher?.key || voucher?.id || firestore().collection('users').doc(userID).collection('walletLog').doc().id
                let ref = firestore().collection('users').doc(userID).collection('walletLog').doc(id)
                voucher.key = id
                if (voucher?.addNew) {
                    voucher.created = Date.now()
                }
                delete voucher.addNew
                voucher.updated = Date.now()
                voucher.userID = userID
                voucher.type = 'voucher'
                await ref.set(voucher, { merge: true })
                try {
                    await firestore().collection('notifications').add({
                        user: userID,
                        to: user_fcm_token,
                        title: voucher?.description === 'Voucher' ? 'Congratulations! You Have a New Voucher' : 'Compensation Granted',
                        body: voucher?.description === 'Voucher' ? `Great news! You've received a voucher worth ${voucher.amount} AED. Check your account to redeem it now.` :
                            `We apologize for any inconvenience. You've been compensated with a voucher worth ${voucher.amount} AED. Please check your account to redeem it.`,
                        read: false
                    })
                } catch (error) {
                    console.log("error notifications RejectedBody", error);
                }
                setLoading(false)
                dismiss()
                createNotification('success', `Voucher ${updatedVoucher?.addNew ? 'created' : 'updated'} successfully`)
            }
        } else {
            createNotification('error', 'User not found')
        }
    }

    return (

        <Modal backdrop="static" size='lg' isOpen={selectedVoucher}>
            <ModalHeader >
                {updatedVoucher?.addNew ? 'New Voucher' : 'Edit Voucher'}
            </ModalHeader>
            <ModalBody>

                {isLoading ? (
                    <div className="loading" />
                ) : ''}
                <Row>

                    {[
                        { key: 'customer', title: 'Customer', type: 'phoneNumber' },
                        { key: 'type', title: 'Select Voucher Type', type: 'select', data: [{ key: 'voucher', value: 'Voucher', label: 'Voucher' }, { key: 'compensation', value: 'Compensation', label: 'Compensation' }] },
                        { key: 'amount', title: 'Voucher Amount', type: 'number' },
                        { key: 'minAmount', title: 'Minimum Order Value to Redeem Voucher', type: 'number', optional: true },
                        { key: 'exp', title: 'Expiry Date', type: 'datepicker' },
                        { key: 'numberOfSplits', title: 'Number of Splits for Voucher Amount', type: 'number', hide: !updatedVoucher?.addNew, optional: true },

                    ].map(r => {
                        return !r.hide && (
                            <Colxx xxs="12" md="12" className='mb-3'>
                                <div className="d-flex align-items-center mb-2" style={{ justifyContent: 'flex-start' }}>
                                    <div>{r.title}</div>
                                    {r.optional && <span
                                        style={{ fontWeight: '100', backgroundColor: '#d4d4d461' }}
                                        className={`ml-1 badge badge-pill badge-light badge-top-left`}
                                    >
                                        Optional
                                    </span>}
                                </div>
                                {r.type === 'select' ?
                                    <Select
                                        components={{ Input: CustomSelectInput }}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        name="form-field-name"
                                        options={r.data}
                                        value={(r.data)?.find(rec => rec.key === updatedVoucher?.[r.key])}
                                        onChange={val => {
                                            UpdateVoucher({ ...updatedVoucher, [r.key]: val.key, description: val.value })
                                        }}
                                    /> : r.type === 'datepicker' ?
                                        <DatePicker
                                            onChange={(date) => {
                                                UpdateVoucher({ ...updatedVoucher, [r.key]: moment(date).valueOf() })
                                            }}
                                            selected={updatedVoucher?.[r?.key]}
                                            dateFormat={'dd/MM/yyyy'}
                                        /> : r.type === 'phoneNumber' ?

                                            <PhoneInput
                                                inputStyle={{ width: '100%', height: '42px', border: '1px solid #d7d7d7' }}
                                                className="customInput"
                                                country={'ae'}
                                                countryCodeEditable={false}
                                                placeholder="e.g. +971xxxxxx"
                                                value={updatedVoucher?.[r.key]}
                                                onChange={(value, data, event, formattedValue) => {
                                                    UpdateVoucher({ ...updatedVoucher, [r.key]: formattedValue })
                                                }}
                                            /> :
                                            <Input
                                                type={r.type}
                                                autoCapitalize="characters"
                                                value={updatedVoucher?.[r.key]}
                                                className="customInput"
                                                onChange={(e) => {
                                                    UpdateVoucher({ ...updatedVoucher, [r.key]: e.target.value })
                                                }} />
                                }
                            </Colxx>
                        )
                    })
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    className="btn-shadow float-right"
                    size="lg"
                    onClick={() => dismiss()}>
                    Dismiss
                </Button>
                <Button
                    color="primary"
                    className="btn-shadow float-right"
                    disabled={isLoading || !updatedVoucher?.customer || !updatedVoucher?.amount || !updatedVoucher?.exp || !updatedVoucher?.type}
                    size="lg"
                    onClick={() => {
                        submit(JSON.parse(JSON.stringify(updatedVoucher)))
                    }}>
                    {updatedVoucher?.addNew ? 'Create Voucher' : 'Update'}
                </Button>
            </ModalFooter>
        </Modal>

    );
};

export default Voucher;